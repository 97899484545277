import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Translate } from "../../../utils/Translate";
import { Button, Typography } from "@material-ui/core";
import IconPlayListPlay from "../../../CommonImages/icon-playlist-play";
import { CustomSwitch } from "./PlayListInfo.Style";
import Routes from "../../../components/Router/Routes";
import { useNavigate } from "react-router-dom";
import { getTranslatedFile } from "../../../utils/utilityfunctions";
import { Notification } from "../../PlayerAndCorousel/views/Player/PlayList/Notification";
const PlayListInfoControlForMobile = (props) => {
  const [showNotification, setNotification] = useState(false);
  const [playBtnEnabled, setPlayBtnEnabled] = useState(false);
  const [isLoop, setPlayListInfoData] = React.useState(
    !!JSON.parse(String(localStorage.getItem("isLoop")).toLowerCase()) ?? false
  );
  const translate = getTranslatedFile();
  const navigate = useNavigate();

  const handleChange = (event) => {
    localStorage.setItem("isLoop", event.target.checked);
    setPlayListInfoData(event.target.checked);
  };

  const handleSubmit = () => {
    if (props?.list && props?.list?.length > 0) {
      const publishedClassFound = props?.list.some((items) => items.isPlayable);
    if (publishedClassFound) {
      navigate(Routes.playList +"/"+props?.playlistId+"/"+isLoop);
      setNotification(false);
    } else {
      setNotification(true);
    }
  }
  };
  useEffect(() => {
    if (props?.list && props?.list?.length > 0) {
      const publishedClassFound = props?.list.some((items) => items.isPlayable);
      if (publishedClassFound) {
        setPlayBtnEnabled(false);
      } else {
        setPlayBtnEnabled(true);
      }
    }
  }, [props?.list]);
  return (
    <>
      <div className="play-list-info-heading-text">
        <span>
          {Translate({
            id: "Playlist.loopPlayBack",
            defaultMessage: "Loop playback",
          })}
        </span>
        <CustomSwitch
          name="isLoop"
          onChange={handleChange}
          value={isLoop}
          checked={isLoop}
          inputProps={{ "aria-label": "Loop" }}
        />
      </div>
      <div className="play-list-info-heading-btn">
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          className="play-list-info-btn"
          onClick={handleSubmit}
          disabled={playBtnEnabled}
        >
          <Typography variant="button">
            {" "}
            {Translate({ id: "Playlist.Play", defaultMessage: "Play" })}{" "}
          </Typography>
        </Button>
      </div>
      {showNotification && (
        <Notification
          {...{
            severity: "error",
            text: translate?.ClassListViews?.ClassNotAvailable,
            show: "open",
          }}
        />
      )}
    </>
  );
};

PlayListInfoControlForMobile.propTypes = {};

export { PlayListInfoControlForMobile };
